<template>
  <div>
    <Table
      title-key="orderNumber"
      :actions="actions"
      :items="$store.getters['withdrawalV2/tableData']"
      :fields="$store.getters['withdrawalV2/tableFields']"
      :loading="$store.state.withdrawalV2.loadingTable"
      :filter="$store.state.withdrawalV2.tableFilter"
      :sort="$store.state.withdrawalV2.tableSort"
      :tab-options="tabOptions"
      :sort-options="sortOptions"
      :keyword="$store.state.withdrawalV2.tableKeyword"
      :pagination="$store.state.withdrawalV2.tablePagination"
      :selected-ids="$store.state.withdrawalV2.selectedIds"
      search-placeholder="Search acc. name, email"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
      @select="select"
      @download="downloadData"
    />

    <!-- Approve confirmation modal -->
    <b-modal
      v-model="approvalModalVisible"
      title="Approve"
      ok-title="Yes, approve"
      cancel-variant="white"
      @ok="approve"
    >
      Are you sure you want to approve this withdrawal request? The money will be sent to the creator.
    </b-modal>
    <b-modal
      v-model="approvalMultipleRowModalVisible"
      title="Approve"
      ok-title="Yes, approve"
      cancel-variant="white"
      @ok="approveMultipleRow"
    >
      Are you sure you want to approve these {{ $store.state.withdrawalV2.selectedIds.length }} withdrawal requests? The money will be sent to the creator.
    </b-modal>

    <!-- Refund confirmation modal -->
    <b-modal
      v-model="refundModalVisible"
      title="Refund"
      ok-title="Yes, refund"
      cancel-variant="white"
      @ok="refund"
    >
      Are you sure you want to refund this withdrawal request? The money <b>won't</b> be credited back to the creator.
    </b-modal>
    <b-modal
      v-model="refundMultipleRowModalVisible"
      title="Refund"
      ok-title="Yes, refund"
      cancel-variant="white"
      @ok="refundMultipleRow"
    >
      Are you sure you want to refund these {{ $store.state.withdrawalV2.selectedIds.length }} withdrawal requests? The money <b>won't</b> be credited back to the creator.
    </b-modal>

    <!-- Reject confirmation modal -->
    <b-modal
      v-model="rejectionModalVisible"
      title="Reject"
      hide-footer
    >
      <p>Are you sure you want to reject this withdrawal request? The money will be credited back to the creator.</p>

      <FormBuilder
        :fields="rejectionFields"
        :loading-submit="$store.state.Game.loadingSubmit"
        success-message="'The withdrawal has been rejected'"
        submit-label="Yes, reject"
        block
        @submit="reject"
        @cancel="rejectionModalVisible = false"
      />
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import FormBuilder from '@/layouts/components/FormBuilder.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    Table,
    BModal,
    FormBuilder,
  },
  data() {
    return {
      // Modal
      approvalModalVisible: false,
      approvalMultipleRowModalVisible: false,
      rejectionModalVisible: false,
      rejectionMultipleRowModalVisible: false,
      refundModalVisible: false,
      refundMultipleRowModalVisible: false,

      selectedData: '',

      // Sort
      sortOptions: [
        {
          key: 'start_date', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'start_date', direction: 'asc', label: 'Oldest' },
      ],

      approvalFields: [
        {
          key: 'description',
          label: 'Reason',
          type: 'textarea',
          minLength: 5,
        },
      ],

      rejectionFields: [
        {
          key: 'reason',
          label: 'Reason',
          type: 'textarea',
          minLength: 5,
        },
      ],

      actions: [
        'view',
        'download',
        {
          type: 'other',
          children: [
            {
              label: 'Approve',
              event: this.confirmApprove,
              multipleRowEvent: this.confirmMultipleRowApprove,
              visibility: {
                callback: data => data.payment_status === 'CREATED' && this.canAccess('manage', 'Withdrawal'),
              },
            },
            {
              label: 'Refund',
              event: this.confirmRefund,
              multipleRowEvent: this.confirmMultipleRowRefund,
              visibility: {
                callback: data => data.payment_status === 'CREATED' && this.canAccess('manage', 'Withdrawal'),
              },
            },
            {
              label: 'Reject',
              event: this.confirmReject,
              multipleRowEvent: this.confirmMultipleRowApprove,
              visibility: {
                callback: data => data.payment_status === 'CREATED' && this.canAccess('manage', 'Withdrawal'),
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    tabOptions() {
      return [
        {
          key: 'statuses', label: 'All', default: true,
        },
        {
          key: 'statuses', label: 'Created', value: 'CREATED', tag: this.$store.state.dashboard.pendingWithdrawalCount, default: true,
        },
        {
          key: 'statuses', label: 'Succeeded', value: 'SUCCEED',
        },
        { key: 'statuses', label: 'Cancelled', value: 'CREATED_CANCELLED' },
        { key: 'statuses', label: 'Failed', value: 'FAILED' },
      ]
    },
  },
  methods: {
    loadData() {
      return this.$store.dispatch('withdrawalV2/getTableData')
    },
    changeFields(val) {
      this.$store.commit('withdrawalV2/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('withdrawalV2/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('withdrawalV2/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('withdrawalV2/SET_TABLE_SORT', val)
    },
    confirmApprove(data) {
      this.selectedData = data.item
      this.approvalModalVisible = true
    },
    confirmMultipleRowApprove() {
      this.approvalMultipleRowModalVisible = true
    },
    approve() {
      this.$store.dispatch('withdrawalV2/changeStatus', {
        id: this.selectedData.id,
        data: {
          status: 'approved',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The withdrawal has been approved',
              variant: 'success',
            },
          })

          this.approvalModalVisible = false
          this.loadData()
        })
    },
    approveMultipleRow() {
      this.$store.dispatch('withdrawalV2/changeStatusMultiple', {
        ids: this.$store.state.withdrawalV2.selectedIds,
        status: 'approved',
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: `${this.$store.state.withdrawalV2.selectedIds.length} withdrawal has been approved`,
              variant: 'success',
            },
          })

          this.approvalMultipleRowModalVisible = false
          this.loadData()

          // reset selected rows
          this.select([])
        })
    },
    confirmRefund(data) {
      this.selectedData = data.item
      this.refundModalVisible = true
    },
    confirmMultipleRowRefund() {
      this.refundMultipleRowModalVisible = true
    },
    refund() {
      this.$store.dispatch('withdrawalV2/changeStatus', {
        id: this.selectedData.id,
        data: {
          status: 'refund',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The withdrawal has been refunded',
              variant: 'success',
            },
          })

          this.refundModalVisible = false
          this.loadData()
        })
    },
    refundMultipleRow() {
      this.$store.dispatch('withdrawalV2/changeStatusMultiple', {
        ids: this.$store.state.withdrawalV2.selectedIds,
        status: 'refund',
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: `${this.$store.state.withdrawalV2.selectedIds.length} withdrawal has been refunded`,
              variant: 'success',
            },
          })

          this.refundModalVisible = false
          this.loadData()

          // reset selected rows
          this.select([])
        })
    },
    confirmReject(data) {
      this.selectedData = data.item
      this.rejectionModalVisible = true
    },
    reject(data, callback) {
      this.$store.dispatch('withdrawalV2/changeStatus', {
        id: this.selectedData.id,
        data: {
          ...data,
          status: 'rejected',
        },
      })
        .then(() => {
          this.rejectionModalVisible = false
          this.loadData()

          callback()
        })
    },
    paginate(val) {
      this.$store.commit('withdrawalV2/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('withdrawalV2/SET_TABLE_KEYWORD', val)
    },
    select(val) {
      this.$store.commit('withdrawalV2/SET_SELECTED_IDS', val)
    },
    downloadData() {
      return this.$store.dispatch('withdrawalV2/downloadTableData')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Your data will be ready in a minutes!',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'export-result' })
        })
    },
  },
}
</script>
